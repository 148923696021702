import React, { useEffect } from 'react';
import Layout from '../components/Layout';

const Manual = () => {
  // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <section id="wrapper">
        <div className="inner" style={{ paddingTop: "40px" }}>
          <h2 className="major" style={{ textAlign: "center" }}>Range Drills User Manual</h2>
          
          <div className="content" style={{ 
            maxWidth: "800px", 
            margin: "0 auto", 
            padding: "30px", 
            backgroundColor: "rgba(27, 31, 34, 0.85)",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            marginTop: "20px",
            marginBottom: "40px"
          }}>
            {/* Table of Contents */}
            <section>
              <h3 className="major">Table of Contents</h3>
              <ul className="alt" style={{ marginBottom: "30px" }}>
                <li><a href="#welcome">Welcome to RangeDrills</a></li>
                <li><a href="#getting-started">Getting Started</a></li>
                <li><a href="#main-sections">Main Sections of the App</a>
                  <ul>
                    <li><a href="#lets-shoot">Let's Shoot</a></li>
                    <li><a href="#drills">Drills</a></li>
                    <li><a href="#history">History</a></li>
                    <li><a href="#shot-clock">Shot Clock / Timer</a></li>
                  </ul>
                </li>
                <li><a href="#session-picture">Session Picture</a></li>
                <li><a href="#tips">Tips for Better Use</a></li>
                <li><a href="#privacy">Privacy & Data</a></li>
                <li><a href="#contact">Contact & Feedback</a></li>
              </ul>
              <hr style={{ margin: "20px 0", opacity: "0.5" }} />
            </section>

            <section id="welcome">
              <h3 className="major">Welcome to RangeDrills</h3>
              <p>
                RangeDrills is your personal training partner for pistol sessions at the range. 
                Designed for shooters of all levels, the app helps you follow preloaded routines, 
                track progress, analyze performance, and refine your shooting skills with purpose.
              </p>
            </section>

            <section id="getting-started">
              <h3 className="major">Getting Started</h3>
              
              <h4>Installation</h4>
              <ul className="alt">
                <li>Download RangeDrills from the provided link.</li>
                <li>Grant camera and notification permissions if prompted.</li>
              </ul>
              
              <h4>Initial Setup</h4>
              <ul className="alt">
                <li>Open the app — for the first time, you'll have to sign up.</li>
                <li>Browse through preloaded drills and routines.</li>
                <li>You're ready to shoot!</li>
              </ul>
            </section>

            <section id="main-sections">
              <h3 className="major">Main Sections of the App</h3>
              
              <h4 id="lets-shoot">1. Let's Shoot</h4>
              <p>This is where you begin your range training.</p>
              <ul className="alt">
                <li>Choose a preloaded routine from the "RangeDrills Sessions" list.</li>
                <li>Tap the routine to view its drills.</li>
                <li>Press Start to begin the session.</li>
                <li>As you complete each drill, mark it as complete.</li>
                <li>You'll have access to:
                  <ul>
                    <li>A shot timer</li>
                    <li>Grouping analysis</li>
                    <li>Training tips</li>
                  </ul>
                </li>
              </ul>
              
              <h4 id="drills">2. Drills</h4>
              <p>Explore the catalog of drills included in your sessions.</p>
              <ul className="alt">
                <li>Each drill shows:
                  <ul>
                    <li>Level (Core, Tactical, Advance, Operator)</li>
                    <li>Description and tips</li>
                    <li>A reference image</li>
                  </ul>
                </li>
                <li>Drills are part of pre-configured sessions — you can't add or remove them individually.</li>
              </ul>
              
              <h4 id="history">3. History</h4>
              <p>Review and reflect on your training sessions.</p>
              <ul className="alt">
                <li>View a list of previously completed sessions.</li>
                <li>Tap on any session to:
                  <ul>
                    <li>See the drills completed</li>
                    <li>View your target image (if uploaded)</li>
                    <li>Check your score and feedback</li>
                  </ul>
                </li>
              </ul>
              
              <h4 id="shot-clock">4. Shot Clock / Timer</h4>
              <p>Train under pressure with a custom shot timer.</p>
              <ul className="alt">
                <li>Configure:
                  <ul>
                    <li>Interval between beeps</li>
                    <li>Number of reps</li>
                    <li>Total session time</li>
                  </ul>
                </li>
                <li>Audio cues guide your tempo</li>
                <li>Compatible with music playback</li>
              </ul>
            </section>

            <section id="session-picture">
              <h3 className="major">Session Picture & AI Scoring (Future)</h3>
              <p>
                At the end of each session, you can upload a photo of your target. In the future:
              </p>
              <ul className="alt">
                <li>The app analyzes shot placement</li>
                <li>Provides a session score</li>
                <li>Offers targeted tips based on your accuracy and groupings</li>
              </ul>
            </section>

            <section id="tips">
              <h3 className="major">Tips for Better Use</h3>
              <ul className="alt">
                <li>Use the Shot Clock to simulate timed drills</li>
                <li>Take clear, well-lit photos of your targets</li>
                <li>Start with Core drills and work your way up to Operator</li>
              </ul>
            </section>

            <section id="privacy">
              <h3 className="major">Privacy & Data</h3>
              <ul className="alt">
                <li>All data is stored locally on your device</li>
                <li>No drills or images are shared unless you choose to</li>
                <li>You're in full control of your range session history</li>
              </ul>
            </section>

            <section id="contact">
              <h3 className="major">Contact & Feedback</h3>
              <p>
                We'd love to hear from you.
              </p>
              <ul className="alt">
                <li>📧 Email us: <a href="mailto:info@rangedrills.io">info@rangedrills.io</a></li>
                <li>🌐 Website: <a href="https://www.rangedrills.io">www.rangedrills.io</a></li>
              </ul>
            </section>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Manual; 