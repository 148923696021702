import React, { useEffect } from 'react';
import Layout from '../components/Layout';

const TermsOfService = () => {
  // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <section id="wrapper">
        <div className="inner" style={{ paddingTop: "40px" }}>
          <h2 className="major" style={{ textAlign: "center" }}>Terms of Service</h2>
          
          <div className="content" style={{ 
            maxWidth: "800px", 
            margin: "0 auto", 
            padding: "30px", 
            backgroundColor: "rgba(27, 31, 34, 0.85)",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            marginTop: "20px",
            marginBottom: "40px"
          }}>
            {/* Table of Contents */}
            <section>
              <h3 className="major">Table of Contents</h3>
              <ul className="alt" style={{ marginBottom: "30px" }}>
                <li><a href="#acceptance">1. Acceptance of Terms</a></li>
                <li><a href="#license">2. Use License</a></li>
                <li><a href="#disclaimer">3. Disclaimer</a></li>
                <li><a href="#limitations">4. Limitations</a></li>
                <li><a href="#revisions">5. Revisions and Errata</a></li>
              </ul>
              <hr style={{ margin: "20px 0", opacity: "0.5" }} />
            </section>
            
            <section id="acceptance" className="mb-8">
              <h3 className="major">1. Acceptance of Terms</h3>
              <p>
                By accessing and using this website and the Range Drills application, you accept and agree to be bound by the terms
                and provision of this agreement.
              </p>
            </section>

            <section id="license" className="mb-8">
              <h3 className="major">2. Use License</h3>
              <p>
                Permission is granted to temporarily download one copy of the materials
                (information or software) on this website for personal, non-commercial
                transitory viewing only.
              </p>
            </section>

            <section id="disclaimer" className="mb-8">
              <h3 className="major">3. Disclaimer</h3>
              <p>
                The materials on this website are provided on an 'as is' basis. We make no
                warranties, expressed or implied, and hereby disclaim and negate all other
                warranties including, without limitation, implied warranties or conditions of
                merchantability, fitness for a particular purpose, or non-infringement of
                intellectual property or other violation of rights.
              </p>
            </section>

            <section id="limitations" className="mb-8">
              <h3 className="major">4. Limitations</h3>
              <p>
                In no event shall we or our suppliers be liable for any damages (including,
                without limitation, damages for loss of data or profit, or due to business
                interruption) arising out of the use or inability to use the materials on this
                website.
              </p>
            </section>

            <section id="revisions" className="mb-8">
              <h3 className="major">5. Revisions and Errata</h3>
              <p>
                The materials appearing on this website could include technical, typographical,
                or photographic errors. We do not warrant that any of the materials on this
                website are accurate, complete or current.
              </p>
            </section>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TermsOfService;